var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Dialog",
    {
      ref: "dialog",
      attrs: {
        title: _vm.$t("title.agency.sendMessage"),
        width: "40%",
        "max-width": "40%",
        color: "next"
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "v-btn",
                {
                  staticClass: "back",
                  attrs: { width: "33%", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("button.close")))]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-container",
        { staticClass: "px-4 mx-4", staticStyle: { width: "auto" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "py-0", staticStyle: { height: "100%" } },
                [
                  _c(
                    "p",
                    {
                      staticClass: "py-0 mb-0 font-weight-black",
                      staticStyle: { "font-size": "20px" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("label.smsMessageBody")) +
                          "\n        "
                      )
                    ]
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticStyle: { height: "100%" } },
            [
              _c(
                "v-col",
                { staticClass: "py-0 pl-0", staticStyle: { height: "100%" } },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-simple-table",
                        {
                          staticClass:
                            "elevation-1 v_base_table_fill_width v_base_table_with_border",
                          staticStyle: { width: "100%" }
                        },
                        [
                          _c(
                            "tbody",
                            _vm._l(_vm.smsMessageRecords, function(
                              smsMessageRecord
                            ) {
                              return _c("tr", { key: smsMessageRecord.key }, [
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "text-left .v_base_table_with_border base_td_category_text"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t(
                                            `header.smsMailMessageTable.${smsMessageRecord.key}`
                                          )
                                        ) +
                                        "\n                "
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { "align-self": "center" } },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass: "mb-0",
                                                staticStyle: {
                                                  "font-size": "14px"
                                                },
                                                attrs: { align: "left" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      smsMessageRecord.value
                                                        ? _vm.$t(
                                                            "description.messageContent.selected"
                                                          )
                                                        : _vm.$t(
                                                            "description.messageContent.unselected"
                                                          )
                                                    ) +
                                                    "\n                      "
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-col",
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "font-weight-black float-right",
                                                attrs: {
                                                  align: "center",
                                                  color: "next",
                                                  dark:
                                                    smsMessageRecord.isEnabled,
                                                  disabled: !smsMessageRecord.isEnabled
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.onClickSelectAndEditSmsMailMessageBodyButton(
                                                      smsMessageRecord,
                                                      true
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("button.edit")
                                                  ) + "\n                      "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ])
                            }),
                            0
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "py-0", staticStyle: { height: "100%" } },
                [
                  _c(
                    "p",
                    {
                      staticClass: "py-0 mb-0 font-weight-black",
                      staticStyle: { "font-size": "20px" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("label.mailMessageBody")) +
                          "\n        "
                      )
                    ]
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "py-0 pl-0", staticStyle: { height: "100%" } },
                [
                  _c(
                    "v-container",
                    { staticClass: "pb-0" },
                    [
                      _c(
                        "v-simple-table",
                        {
                          staticClass:
                            "elevation-1 v_base_table_fill_width v_base_table_with_border",
                          staticStyle: { width: "100%" }
                        },
                        [
                          _c(
                            "tbody",
                            _vm._l(_vm.mailMessageRecords, function(
                              mailMessageRecord
                            ) {
                              return _c("tr", { key: mailMessageRecord.key }, [
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "text-left .v_base_table_with_border base_td_category_text"
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t(
                                            `header.smsMailMessageTable.${mailMessageRecord.key}`
                                          )
                                        ) +
                                        "\n                "
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { "align-self": "center" } },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass: "mb-0",
                                                staticStyle: {
                                                  "font-size": "14px"
                                                },
                                                attrs: { align: "left" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      mailMessageRecord.value
                                                        ? _vm.$t(
                                                            "description.messageContent.selected"
                                                          )
                                                        : _vm.$t(
                                                            "description.messageContent.unselected"
                                                          )
                                                    ) +
                                                    "\n                      "
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-col",
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "font-weight-black float-right",
                                                attrs: {
                                                  align: "center",
                                                  color: "next",
                                                  dark:
                                                    mailMessageRecord.isEnabled,
                                                  disabled: !mailMessageRecord.isEnabled
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.onClickSelectAndEditSmsMailMessageBodyButton(
                                                      mailMessageRecord,
                                                      false
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("button.edit")
                                                  ) + "\n                      "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ])
                            }),
                            0
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ErrorDialog", { ref: "errorDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import axios from '@/apis/axios';
import { getAccessToken } from '@/lib/api';

/**
 * 案件送信用メッセージ本文取得
 * @param {Number} contractId 契約ID
 */
export const getSendTextManagements = async contractId => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get(`/agencies/contracts/${contractId}/send_text_managements`, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 案件送信用メッセージ本文更新
 */
export const updateSendTextManagement = async (id, contractId, messageBody) => {
  const accessToken = await getAccessToken();

  const response = await axios
    .put(
      `/agencies/contracts/${contractId}/send_text_managements/${id}`,
      { ...messageBody },
      { headers: { 'access-token': accessToken } }
    )
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

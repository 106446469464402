var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Dialog",
    {
      ref: "dialog",
      attrs: { title: _vm.$t("title.agency.extraFileList"), color: "next" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "v-btn",
                {
                  staticClass: "back",
                  staticStyle: { "font-size": "16px" },
                  attrs: { width: "33%", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("button.close")) + "\n    ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "next",
                  staticStyle: { "font-size": "16px" },
                  attrs: { width: "33%", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.onClickCreate()
                    }
                  }
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("button.add")) + "\n    ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-container",
        [
          _c("v-data-table", {
            staticClass: "elevation-1 ma-3",
            attrs: {
              "calculate-widths": "",
              headers: _vm.headers,
              items: _vm.files,
              "hide-default-footer": true,
              loading: _vm.isLoading,
              "loading-text": _vm.$t("description.extraFileReading"),
              "no-data-text": _vm.$t("error.extraFileNotFound"),
              "items-per-page": -1
            },
            scopedSlots: _vm._u(
              [
                {
                  key: `item.detail`,
                  fn: function({ item }) {
                    return [
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "", left: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function({ on }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          attrs: {
                                            color: "red",
                                            fab: "",
                                            "x-small": "",
                                            dark: ""
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "white" } },
                                          [_vm._v("edit")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c(
                            "v-list",
                            { staticClass: "ma-0 pa-0" },
                            [
                              _vm.extensionCheck(item.name)
                                ? _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.onClickPreview(item)
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [_vm._v("mdi-eye-outline")]),
                                      _c(
                                        "v-list-item-action",
                                        {
                                          staticClass: "mx-0",
                                          attrs: { color: "next" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("button.preview"))
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.onClickEdit(item)
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", [_vm._v("edit")]),
                                  _c(
                                    "v-list-item-action",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { color: "next" }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("button.edit")))]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.onClickDelete(item)
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", [_vm._v("delete")]),
                                  _c(
                                    "v-list-item-action",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { color: "next" }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("button.delete")))]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: `item.comment`,
                  fn: function({ item }) {
                    return _vm._l(_vm.splitMessageLine(item.comment), function(
                      comment,
                      index
                    ) {
                      return _c(
                        "p",
                        {
                          key: index,
                          staticClass: "ma-0 pa-0",
                          attrs: { align: "left" }
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(comment) + "\n        "
                          )
                        ]
                      )
                    })
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _c("ErrorDialog", { ref: "errorDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import axios from '@/apis/axios';
import { getAccessToken } from '@/lib/api';

export const getSendTemplateTextManagements = async () => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get(`/agencies/user/send_template_text_managements/`, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

export const putSendTemplateTextManagement = async sendTemplateTextManagement => {
  const accessToken = await getAccessToken();

  const response = await axios
    .put(
      `/agencies/user/send_template_text_managements/${sendTemplateTextManagement.id}`,
      { message: sendTemplateTextManagement.message },
      { headers: { 'access-token': accessToken } }
    )
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

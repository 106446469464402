<template>
  <Dialog ref="dialog" :title="$t('title.agency.fileList')" :color="'next'">
    <v-container>
      <v-layout column wrap>
        <v-row>
          <p class="py-4 px-3">{{ $t('description.fileListDialog.text') }}</p>
        </v-row>
        <v-row>
          <p class="py-1 px-3 font-weight-black">
            {{ $t('description.fileListDialog.autoImportTitle') }}
          </p>
        </v-row>
        <v-row style="height:60px" class="mt-1">
          <v-col>
            {{ $t('label.importantMatter') }}
          </v-col>
          <v-col>
            <v-btn
              class="font-weight-black float-right"
              align="center"
              color="next"
              :dark="isDownloadImportantMatterButtonEnabled"
              :disabled="!isDownloadImportantMatterButtonEnabled"
              @click="onClickDownloadImportantMatterButton()"
              >{{ $t('button.downloadFile') }}</v-btn
            >
          </v-col>
        </v-row>
        <template v-if="extraFileList && extraFileList.length > 0">
          <v-row>
            <p class="pt-6 px-3 font-weight-black">
              {{ $t('description.fileListDialog.userUploadExtraTitle') }}
            </p>
          </v-row>
          <v-row
            v-for="extraFile in extraFileList"
            :key="extraFile.id"
            class="mt-1"
          >
            <v-col
              ><p style="word-break: break-all;">{{ extraFile.name }}</p></v-col
            >
            <v-col>
              <v-btn
                class="font-weight-black float-right"
                align="center"
                color="next"
                dark
                @click="onClickDownloadExtraPictureButton(extraFile)"
                >{{ $t('button.downloadFile') }}</v-btn
              >
            </v-col>
          </v-row>
        </template>
        <template v-if="insuredUploads && insuredUploads.length > 0">
          <v-row>
            <p class="pt-4 px-3 font-weight-black">
              {{ $t('description.fileListDialog.userUploadTitle') }}
            </p>
          </v-row>
          <v-row
            v-for="insuredUpload in insuredUploads"
            :key="insuredUpload.id"
            class="py-1"
          >
            <v-col>
              <p style="word-break: break-all;">
                {{ insuredUpload.fileName }}
              </p>
            </v-col>
            <v-col>
              <v-btn
                class="font-weight-black float-right"
                align="center"
                color="next"
                dark
                @click="onClickDownloadInsuredUploadButton(insuredUpload)"
                >{{ $t('button.downloadFile') }}</v-btn
              >
            </v-col>
          </v-row>
        </template>
      </v-layout>
    </v-container>

    <template v-slot:footer>
      <v-btn width="33%" class="back" dark @click="close()">{{
        $t('button.close')
      }}</v-btn>
    </template>
    <ErrorDialog ref="errorDialog"></ErrorDialog>
  </Dialog>
</template>

<script>
import { getInsuredUpload } from '@/apis/agency/insuredUploads';
import {
  getContractImportantMatter,
  getExtraPicture,
} from '@/apis/agency/contracts';
import Dialog from '@/components/organisms/agency/Dialog';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';
import { downloader } from '@/lib/commonUtil'

export default {
  components: { Dialog, ErrorDialog },
  data() {
    return {
      // 案件ID
      contractId: null,

      // 重要事項等説明書ID
      importantMatterId: null,

      // 重要事項等説明書ダウンロードボタンが有効であるかどうか
      isDownloadImportantMatterButtonEnabled: false,

      // お客様画像
      insuredUploads: [],

      // 申込内容以外データ
      extraFileList: [],
    };
  },
  methods: {
    open() {
      this.$refs.dialog.open();
    },
    close() {
      this.$refs.dialog.close();
    },
    set(
      contractId,
      importantMatter,
      insuredUploads,
      extraFileList
    ) {
      this.$set(this, 'contractId', contractId);
      this.$set(this, 'insuredUploads', insuredUploads);
      this.$set(this, 'extraFileList', extraFileList);

      // 重要事項等説明書ダウンロードボタンの有効状態をセットする
      this.$set(
        this,
        'isDownloadImportantMatterButtonEnabled',
        !!importantMatter
      );
    },

    // 重要事項等説明書ダウンロードボタンの押下時
    async onClickDownloadImportantMatterButton() {
      const importantMatter = await getContractImportantMatter(this.contractId);
      this.download(
        importantMatter,
        this.$t('file.explanationsForImportantMatter')
      );
    },
    // 申込内容以外データダウンロードボタンの押下時
    async onClickDownloadExtraPictureButton(file) {
      const extraPicture = await getExtraPicture(this.contractId, file.id);
      this.download(extraPicture, file.name);
    },
    // お客様提出書類ダウンロードボタンの押下時
    async onClickDownloadInsuredUploadButton(file) {
      const extraPicture = await getInsuredUpload(this.contractId, file.id);
      this.download(new Blob([extraPicture]), file.fileName);
    },
    download(blob, fileName) {
      if (blob) {
        downloader(blob, fileName);
      }
    },
  },
};
</script>

<template>
  <Dialog ref="dialog" :title="$t('label.sendingConfirm')" :color="'next'">
    <v-container>
      <v-form lazy-validation ref="form" @submit.prevent>
        <div class="text-center" style="margin: 0 auto;">
          <p>{{ $t('description.sendMailAndSms.sendingConfirmText') }}</p>
          <p>{{ $t('description.sendMailAndSms.sendingConfirmSubText') }}</p>
          <br />
          <p>
            {{ $t('description.sendMailAndSms.fullNameKanji')
            }}{{ this.condition.fullNameKanji }}
          </p>
          <p v-if="this.condition.isSms">
            {{ $t('description.sendMailAndSms.mobileNumber')
            }}{{ this.condition.mobileNumber }}
          </p>
          <p v-if="this.condition.isMail">
            {{ $t('description.sendMailAndSms.mail') }}{{ this.condition.mail }}
          </p>
          <p v-if="this.condition.authFlg">
            {{ $t('description.sendMailAndSms.auth')
            }}{{ $t(`label.${ReverseAuthType[this.condition.authFlg]}`) }}
          </p>
        </div>
      </v-form>
    </v-container>

    <template v-slot:footer>
      <v-btn
        width="30vw"
        max-width="170px"
        style="font-size:15px !important"
        class="back"
        dark
        @click="close()"
        >{{ $t('button.cancel') }}</v-btn
      >
      <v-btn
        width="30vw"
        max-width="170px"
        style="font-size:15px !important"
        class="next"
        :dark="isSendButtonEnabled"
        :disabled="!isSendButtonEnabled"
        @click="onClickSendButton()"
      >
        {{ $t('button.send') }}
      </v-btn>
    </template>
    <ErrorDialog ref="errorDialog"></ErrorDialog>
  </Dialog>
</template>

<script>
import Dialog from '@/components/organisms/agency/Dialog';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';
import { ReverseAuthType } from '@/lib/const';

export default {
  components: { Dialog, ErrorDialog },
  data() {
    return {
      // 編集可能な必要書類関連情報
      condition: {
        contractId: '',
        fullNameKanji: '',
        isSms: false,
        phoneNumber: '',
        isMail: false,
        mail: '',
        authFlg: '',
        isChangeStatusEvent: false,
      },
      // 登録ボタンが有効であるかどうか
      isSendButtonEnabled: true,

      ReverseAuthType: ReverseAuthType,
    };
  },
  mounted() {},
  methods: {
    open() {
      this.$refs.dialog.open();
    },
    close() {
      this.$refs.dialog.close();
    },
    init(sendMethod, condition, isChangeStatusEvent) {
      this.$set(this, 'condition', condition);
      this.condition.authFlg = sendMethod.authFlg;
      this.condition.isMail = sendMethod.isMail;
      this.condition.isSms = sendMethod.isSms;
      this.condition.isChangeStatusEvent = isChangeStatusEvent;
      this.$set(this, 'isSendButtonEnabled', true);
    },
    onClickSendButton() {
      this.$set(this, 'isSendButtonEnabled', false);
      const condition = {
        ...(this.condition.isSms && {
          isSms: this.condition.isSms,
        }),
        ...(this.condition.isMail && {
          isMail: this.condition.isMail,
        }),
        ...(this.condition.authFlg && {
          authFlg: this.condition.authFlg,
        }),
        contractId: this.condition.contractId,
        isChangeStatusEvent: this.condition.isChangeStatusEvent,
      };
      this.$emit('onSuccess', condition);
    },
    onErrorSend() {
      this.$refs.errorDialog.open(
        this.$t('title.agency.updateResult'),
        this.$t('error.updateFailed')
      );
    },
  },
};
</script>

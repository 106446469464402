<template>
  <Dialog
    ref="dialog"
    :title="title"
    :width="'85%'"
    :max-width="'85%'"
    :color="'next'"
  >
    <v-container class="px-4 mx-4" style="width:auto">
      <ValidationObserver ref="observer">
        <v-form lazy-validation ref="form" @submit.prevent>
          <v-row class="pl-4">
            <!-- テンプレート一覧 -->
            <p
              class="pl-2 ma-0 font-weight-black"
              style="font-size:15px; height: 100%"
            >
              {{ $t('label.templateList') }}
            </p>
            <p class="pl-2 ma-0" style="font-size:15px; height: 100%">
              {{ $t('description.messageContent.templateListText') }}
            </p>
          </v-row>
          <v-card>
            <v-card-actions>
              <template>
                <v-col
                  class="py-1"
                  v-for="(template, index) in templateList.patternList"
                  :key="index"
                >
                  <v-btn
                    color="next"
                    fab
                    x-small
                    dark
                    @click="preview(template, index)"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                  <strong class="pl-1">{{ replace(index) }}</strong>
                </v-col>
              </template>
            </v-card-actions>
          </v-card>
          <v-row class="pt-4">
            <v-col>
              <template>
                <v-row class="pl-4">
                  <!-- メール送信本文 -->
                  <p
                    class="pl-2 ma-0 font-weight-black"
                    style="font-size:15px; height: 100%"
                  >
                    {{ $t('label.sendMessageBody') }}
                  </p>
                  <p class="pl-2 ma-0" style="font-size:15px; height: 100%">
                    {{ $t('description.messageContent.sendMessageBodyText') }}
                  </p>
                </v-row>
                <ValidationProvider
                  :rules="messageRecord.rules"
                  immediate
                  v-slot="{ errors }"
                >
                  <v-textarea
                    rows="15"
                    outlined
                    clearable
                    clear-icon="cancel"
                    :counter="messageRecord.counterMax"
                    ref="textarea"
                    v-model="messageRecord.value"
                  ></v-textarea>
                  <ValidationError class="ma-0" :error="errors[0]" />
                </ValidationProvider>
              </template>
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>
      <template v-if="isSms">
        <v-card-text class="font-weight-black text-right pr-2 pb-1 mx-auto">
          {{ $t('description.messageContent.sendMessageFooter.sms') }}
        </v-card-text>
      </template>
      <template v-else>
        <v-card-text class="font-weight-black text-right pr-2 pb-1 mx-auto">
          {{ $t('description.messageContent.sendMessageFooter.mail') }}
        </v-card-text>
      </template>
      <v-card-text class="text-right pt-0 pr-2 pb-1 mx-auto">
        {{ $t('description.messageContent.sendMessageFooter.contractUrl') }}
      </v-card-text>
      <template v-if="!isSms">
        <v-card-text class="text-right pt-0 pr-2 pb-1 mx-auto">
          {{
            $t(
              'description.messageContent.sendMessageFooter.modificationContent'
            )
          }}
        </v-card-text>
      </template>
    </v-container>
    <template v-slot:footer>
      <v-btn width="22%" class="back" dark @click="close()">{{
        $t('button.cancel')
      }}</v-btn>
      <v-btn width="22%" class="next" dark @click="onClickConfirmButton()">
        {{ $t('button.confirm') }}
      </v-btn>
    </template>

    <ErrorDialog ref="errorDialog"></ErrorDialog>
    <!--テンプレートプレビュー -->
    <SimpleDialog
      :maxWidth="900"
      :showDialog="showPreviewDialog"
      :title="previewTitle"
      :text="null"
      :subText="null"
      :multiLineText="previewMessage"
      :negativeButtonTitle="$t('button.back')"
      :positiveButtonTitle="
        $t('button.applyTemplate', {
          templateName: previewTitle,
        })
      "
      :onClickNegativeButton="closePreviewDialog"
      :onClickPositiveButton="applyTemplate"
    />
  </Dialog>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ValidationError from '@/components/organisms/common/ValidationError';
import '@/lib/veeValidation';
import { replaceMessage } from '@/lib/commonUtil';
import SimpleDialog from '@/components/organisms/agency/SimpleDialog';
import Dialog from '@/components/organisms/agency/Dialog';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ValidationError,
    SimpleDialog,
    Dialog,
    ErrorDialog,
  },
  data() {
    return {
      isSms: false,
      messageRecord: {},
      contractInfoForReplace: {},
      templateList: {},
      showPreviewDialog: false,
      previewTitle: null,
      previewMessage: null,
      title: null,
      availableTags: {},
    };
  },
  mounted() {},
  methods: {
    open() {
      this.$refs.dialog.open();
    },
    close() {
      this.$refs.dialog.close();
    },
    async init(contractId, isSms, messageRecord, contractInfoForReplace) {
      this.$set(this, 'isSms', isSms);
      this.$set(this, 'messageRecord', messageRecord);
      this.$set(this, 'templateList', messageRecord.template);
      this.$set(this, 'contractInfoForReplace', contractInfoForReplace);
      this.$set(
        this,
        'title',
        this.$t(`header.smsMailMessageTable.${messageRecord.key}`)
      );
    },
    replace(text, isNameOnly) {
      const convertJapanese = text.replace(
        /pattern/g,
        this.$t('description.messageContent.pattern')
      );
      return !isNameOnly && text === this.templateList.default
        ? convertJapanese + this.$t('description.messageContent.default')
        : convertJapanese;
    },
    async preview(template, index) {
      const sendMessageForPreview = await replaceMessage(
        Object.values(template).join(''),
        this.contractInfoForReplace
      );
      this.$set(this, 'previewTitle', this.replace(index, true));
      this.$set(this, 'previewMessage', sendMessageForPreview);
      this.$set(this, 'showPreviewDialog', true);
    },
    applyTemplate() {
      this.$set(this.messageRecord, 'value', this.previewMessage);
      this.$set(this, 'showPreviewDialog', false);
    },
    closePreviewDialog() {
      this.$set(this, 'showPreviewDialog', false);
    },
    async onClickConfirmButton() {
      if (await this.$refs.observer.validate()) {
        this.$emit('onSuccess', {[this.messageRecord.key]: this.messageRecord.value}, this.messageRecord.id);
      }
    },
    onErrorSend() {
      this.$refs.errorDialog.open(
        this.$t('title.agency.updateResult'),
        this.$t('error.updateFailed')
      );
    },
  },
};
</script>
<style>
.v_base_table_with_border {
  border: solid 1px;
}
.v_base_table_fill_width {
  width: 50%;
}
.v_base_td_category_text {
  background: #cccccc;
}
.v-data-table {
  overflow: hidden;
}
</style>

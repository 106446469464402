var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Dialog",
    {
      ref: "dialog",
      attrs: { title: _vm.$t("label.reflectContractInfo"), color: "next" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "v-btn",
                {
                  staticClass: "back",
                  attrs: { width: "33%", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("button.no")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "next",
                  attrs: { width: "33%", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.onClickConfirmButton()
                    }
                  }
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("button.yes")) + "\n    ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-container",
        [
          _c(
            "v-form",
            {
              ref: "form",
              attrs: { "lazy-validation": "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c("v-row", [
                _c(
                  "div",
                  {
                    staticClass: "text-center",
                    staticStyle: { margin: "0 auto" }
                  },
                  [
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.$t("description.reflectContractInfo.title"))
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("description.reflectContractInfo.subTitle")
                        )
                      )
                    ]),
                    _c("br"),
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(
                              "description.reflectContractInfo.changeBefore"
                            )
                          ) +
                          _vm._s(_vm.currentValue) +
                          "\n          "
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(
                              "description.reflectContractInfo.afterChange"
                            )
                          ) +
                          _vm._s(_vm.changeValue) +
                          "\n          "
                      )
                    ])
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("ErrorDialog", { ref: "errorDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }